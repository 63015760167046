<template>
  <div class="login-page">
    <div class="login-panel flex">
      <div class="flex-1 flex flex-column justify-between h-100-p" style="padding: 30px 0 60px 30px; font-size: 45px; color: #fff;opacity:.85;">
        <div>
          <p style="display:inline-block;flex-shrink:0;border-bottom: 3px solid #fff;padding: 15px 0;margin-right:30px;letter-spacing: 3px;line-height: 1.2;">
            {{getSystemName()}}
          </p>
        </div>
        <span style="font-size: 120px;font-weight: 100;letter-spacing: 3px;">welcome</span>
      </div>
      <div class="w-365 h-100-p flex flex-column justify-center align-center bg-white" >
        <span class="login-label mb-40">登 录</span>
        <el-form class="login-form w-267" @submit.native.prevent>
          <div class="flex flex-column">
            <div class="relative">
              <i class="el-icon-user-solid" style="color:#999;position:absolute;top:50%;transform:translateY(-50%);left:10px;z-index:9;"></i>
              <el-input size="medium" v-model.trim="loginForm.loginName" clearable placeholder="登录账号" autocomplete></el-input>
            </div>
          </div>

          <div class="flex flex-column mt-30">
            <div class="relative">
              <i class="iconfont icon-mima" style="color:#999;position:absolute;top:50%;transform:translateY(-50%);left:10px;z-index:9;"></i>
              <el-input size="medium" v-model.trim="loginForm.pwd" show-password clearable placeholder="登录密码" >
              </el-input>
            </div>
          </div>

          <div class="flex flex-column mt-30">
            <div class="relative flex">
              <i class="iconfont icon-yanzhengma" style="color:#999;position:absolute;top:50%;transform:translateY(-50%);left:10px;z-index:9;"></i>
              <el-input size="medium" class="flex-1" v-model.trim="loginForm.veriCodeVal" placeholder="验证码" maxLength="4"></el-input>
              <img class="ml-10" style="height:34px" :src="codeImg" @click="initVerifyCode">
            </div>
          </div>

          <div class="mt-36 mb-40">
            <el-button size="medium" native-type="submit" class="w-250" type="primary" @click="doLogin" :loading="loading">登 录</el-button>
          </div>

          <div class="mb-40" style="margin-top:-20px;" v-if="hasHistory()">
            <el-button size="medium" class="w-250" @click="nav2History">旧版本入口</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div v-if="showICP" class="fs-14 flex align-center justify-center mb-15 gray" style="position: fixed; bottom: 10px; left: 50%; transform: translateX(-50%);">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
        target="view_window"
        class="mr-10 gray"
      >浙ICP备11066014号-6</a>©{{new Date().getFullYear()}} 浙江网泽科技有限公司
    </div>
  </div>
</template>

<script>
import { getVerifyCode } from '@/apis/login'
import { mapActions, mapState } from 'vuex'
import { getSystemName } from '@/utils/settings'
import { sm4 as Sm4 } from 'gm-crypt'

export default {
  name: 'login',
  data () {
    return {
      loading: false,
      loginForm: {
        avoiLogDay: 7, // 免登录天数
        loginName: '', // 账号
        pwd: '', // 密码
        veriCodeKey: '', // 验证码key
        veriCodeVal: '' // 验证码值
      },
      passLogin: true,
      codeImg: '', // 验证码图片
      codeExpires: 0, // 验证码有效期
      redirect: '', // 登录后跳转的页面
      showICP: false
    }
  },
  computed: {
    ...mapState('account', ['comps'])
  },
  watch: {
    // 如果url包含query.redirect，保存起来，登录后跳转到该路径
    $route: {
      handler ({ query }) {
        if (query && query.redirect) this.redirect = query.redirect
      },
      immediate: true
    }
  },
  created () {
    this.initVerifyCode()
    this.showICP = window.location.origin.includes('wazert')
  },
  methods: {
    getSystemName,

    // 登录 vuex action
    ...mapActions('account', ['login', 'getComps', 'perms']),

    // 获取验证码
    async initVerifyCode () {
      const { data, code, msg } = await getVerifyCode()
      if (code == 200) {
        this.codeImg = data.img
        this.codeExpires = data.expires
        this.loginForm.veriCodeKey = data.veriCodeKey
      } else {
        this.$message.error(msg)
      }
    },

    // 验证登录表单
    passVerify () {
      const loginForm = this.loginForm
      const verifys = [
        { prop: 'loginName', message: '请输入用户名' },
        { prop: 'pwd', message: '请输入密码' },
        { prop: 'veriCodeVal', message: '请输入验证码' }
      ]
      const notPass = verifys.some(({ prop, message }) => {
        const value = loginForm[prop]
        if (!value) this.$message.error(message)
        return !value
      })
      return !notPass
    },

    // 登录
    doLogin () {
      this.loading = true
      const loginForm = { ...this.loginForm }
      const sm4 = new Sm4({
        key: 'abcdefghijklmnop', // 这里这个key值是跟后端要的
        mode: 'ecb', // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，不过要是cbc的话下面还要加一个iv的参数，ecb不用
        cipherType: 'base64'
      })
      loginForm.pwd = sm4.encrypt(loginForm.pwd)
      // 验证，登录，跳转
      this.login(loginForm).then((res) => {
        const { code, msg } = res
        this.loading = false
        if (code == 200) {
          this.$router.push({ path: this.redirect || '/' })
        } else {
          this.$message.error(msg)
          this.initVerifyCode()
        }
      })
    },

    nav2History () {
      window.location.href = location.origin + '/hzConcreteMS/'
    },

    hasHistory () {
      return location.origin.includes('beton.wazert.com')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  position: relative;
  overflow: auto;
  height: 100%;
  background: #000 url('~@/assets/imgs/common/dlv-bg.png') no-repeat center;
  background-size: 100%;

  .login-panel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    height: 600px;
    border-radius: 3px;
    background: rgba(255, 255, 255, .2);
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .2);
  }

  .login-label {
    font-size: 22px;
    padding: 10px 0;
    border-bottom: 2px solid #86B4D9;
  }

  .login-form {
    padding: 1px;
  }
}
::v-deep {
  .el-input--medium .el-input__inner {
    padding: 0 15px 0 36px;
    border-radius: 0;
    border-width: 0;
    border-bottom-width: 1px;
    &:focus {
      border-color: #4C9FFD!important;
    }
  }

  .el-button {
    border-radius: 0;
  }

  .el-button--primary {
    background-color: #4C9FFD;
    border-color: transparent!important;
    &:active {
      opacity: .8;
    }
  }

  .el-input__icon.el-icon-view {
    display: none;
  }
}
</style>
